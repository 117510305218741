import React, { useCallback, useEffect, useState, useMemo } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { Dialog, Popper } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import theme from '../../styleguide/theme'

import { StyledDatePickerWrapper } from './style'

const StyledDatePicker = styled(DatePicker<Date | null>)`
	.Mui-focused {
		color: ${theme.palette.grey[700]};
	}
`

const GlobalStyle = createGlobalStyle`
    .MuiPopper-root.MuiPickersPopper-root {
        z-index: 1500;
    }
`

export type TeloDatePickerProps = {
	initialDate?: Date
	format: string
	label?: string
	name?: string
	helperText?: React.ReactNode | string
	isError?: boolean
	disableFuture?: boolean
	minDate?: Date
	maxDate?: Date
	disablePast?: boolean
	disabled?: boolean
	slots?: Record<string, unknown>
	slotProps?: Record<string, unknown>
	ToolbarComponent?: React.JSXElementConstructor<any>
	onChange?: (selectedDate: Date | null) => void
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
	value?: Date | null
}

const initialMinDate = new Date('01/01/1900')

export const TeloDatePicker = ({
	initialDate,
	format,
	label,
	name,
	helperText,
	isError,
	disableFuture,
	minDate: $minDate,
	maxDate,
	disablePast,
	disabled,
	ToolbarComponent,
	onChange,
	onBlur,
	value,
	slots,
	slotProps,
	...otherProps
}: TeloDatePickerProps) => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(
		initialDate ?? null,
	)
	const minDate = $minDate || initialMinDate

	const handleChange = useCallback(
		(date: Date | null) => {
			if (minDate && date && date.getTime() < minDate.getTime()) {
				return
			}
			setSelectedDate(date)
			onChange?.(date)
		},
		[onChange, minDate],
	)

	useEffect(() => {
		if (value || value === null) {
			setSelectedDate(value)
		}
	}, [value])

	const memoizedPopper = useMemo(
		() => (props: any) =>
			(
				<Popper
					{...props}
					sx={{ ...props.sx, zIndex: theme.zIndex.autocompleteModalPopper }}
				/>
			),
		[],
	)
	// for Mobile
	const memoizedDialog = useMemo(
		() => (props: any) =>
			(
				<Dialog
					{...props}
					sx={{
						...props.sx,
						zIndex: theme.zIndex.autocompleteModalPopper,
						maxWidth: 'unset',
					}}
				/>
			),
		[],
	)

	return (
		<StyledDatePickerWrapper>
			<GlobalStyle />
			<StyledDatePicker
				onChange={handleChange}
				value={selectedDate}
				format={format}
				label={label}
				disableFuture={disableFuture}
				disablePast={disablePast}
				disabled={disabled}
				minDate={minDate}
				maxDate={maxDate}
				slots={{
					toolbar: ToolbarComponent,
					popper: memoizedPopper,
					openPickerIcon: CalendarTodayOutlinedIcon,
					dialog: memoizedDialog,
					...slots,
				}}
				slotProps={{
					...slotProps,
					textField: {
						...otherProps,
						name,
						helperText,
						error: isError,
						onBlur,
					},
				}}
			/>
		</StyledDatePickerWrapper>
	)
}
