import { get } from 'lodash'
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
	ChangeEvent,
	useMemo,
} from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, OutlinedTextFieldProps } from '@mui/material'
import { FieldProps } from '../../model/types'
import UncontrolledInput from '../Input'
import ChevronLeftIcon from '../icons/ChevronLeftIcon'
import ChevronRightIcon from '../icons/ChevronRightIcon'
import styled from 'styled-components'
import { capitalizeFirstLetter } from '../../libs/utils'
import { InputHelperTextWithIcon } from './InputHelperTextWithIcon'
import InputAdornment from '@mui/material/InputAdornment'
import CloseIcon from '../icons/CloseIcon'

const StyledIconButton = styled(IconButton)`
	margin-left: 0;
	margin-right: 0;
	padding: 0;
`

export interface FormInputProps extends FieldProps, OutlinedTextFieldProps {
	onChangeValidate?(
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void
	onBlurValidate?(
		name: string,
		e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void
	values: any
}

export type Props = FormInputProps & {
	id: string
	maxLength?: number
	disableMaxLength?: boolean
	numberOfDecimals?: number
	paddingZeros?: number
	semicontrolled?: boolean
	showErrorOnlyIfTouched?: boolean
	showPlusSign?: boolean
	showScrollButtons?: boolean
	onlyPositiveNumbers?: boolean
	strictlyPositive?: boolean
	validateBlank?: boolean
	strictlyDigit?: boolean
	onChangeValue?(
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void
	onInputBlur?(value: string): void
	CustomError?: React.FC<{}>
	clearable?: boolean
	showInstantPlaceholder?: boolean
}

const Input: React.FC<Props> = ({
	children,
	field,
	form: { touched, errors, setFieldValue, setFieldError },
	id,
	inputProps,
	maxLength = 400,
	disableMaxLength = false,
	numberOfDecimals,
	paddingZeros,
	semicontrolled,
	showErrorOnlyIfTouched = true,
	showPlusSign = false,
	onlyPositiveNumbers = false,
	strictlyPositive = false,
	strictlyDigit = false,
	showScrollButtons,
	onChangeValue,
	onInputBlur,
	validateBlank = true,
	clearable,
	showInstantPlaceholder,
	...props
}) => {
	const { t } = useTranslation()
	const [internalValue, setInternalValue] = useState('')

	const onlyDigits = useMemo(() => {
		return /^-?\d+$/ //This regex ensures that the string contains only digits (0-9), optionally preceded by a hyphen (-) at the start, with no other characters allowed.
	}, [])

	const onlyPositiveNumber = useMemo(() => {
		return /^[+]?([0-9]+(?:[.][0-9]*)?|\.[0-9]+)$/
	}, [])

	useEffect(() => {
		if (strictlyDigit && !onlyDigits.test(internalValue) && validateBlank) {
			setFieldError(field.name, t('forms.numericDigitsOnly'))
		}
		if (
			strictlyPositive &&
			!onlyPositiveNumber.test(internalValue) &&
			validateBlank
		) {
			setFieldError(field.name, t('validation.positiveNumber'))
		}
	}, [
		validateBlank,
		field.name,
		internalValue,
		onlyDigits,
		onlyPositiveNumber,
		setFieldError,
		strictlyDigit,
		strictlyPositive,
		t,
	])

	useEffect(() => {
		setInternalValue('')
	}, [field.name])

	const fieldError = get(errors, field.name)
	const fieldTouched = !!get(touched, field.name)

	const showError = showErrorOnlyIfTouched
		? !!fieldError && fieldTouched
		: !!fieldError

	const paddingModifier = useCallback(
		(value: string) => {
			if (paddingZeros) {
				return String(value).padStart(paddingZeros, '0')
			} else return value
		},
		[paddingZeros],
	)

	const decimalsModifier = useCallback(
		(value: string) => {
			if (value.length) {
				return Number(value).toFixed(numberOfDecimals).toString()
			} else return value
		},
		[numberOfDecimals],
	)

	const addPlusSign = useCallback((value: string) => {
		const shouldAddSign = Number(value) > 0
		if (value.length && shouldAddSign) {
			return '+'.concat(value)
		} else return value
	}, [])

	const formatValue = useCallback(
		(value: string) => {
			let formattedValue = value
			formattedValue = numberOfDecimals
				? decimalsModifier(formattedValue)
				: formattedValue
			formattedValue = showPlusSign
				? addPlusSign(formattedValue)
				: formattedValue

			return formattedValue
		},
		[numberOfDecimals, decimalsModifier, addPlusSign, showPlusSign],
	)

	const inputElement = id ? document.getElementById(id) : undefined

	const isTextLongerThanField = useCallback(
		() => inputElement && inputElement.offsetWidth < inputElement.scrollWidth,
		[inputElement],
	)

	const [showBackScrollButton, setShowBackScrollButton] = useState(false)
	const [showFrontScrollButton, setShowFrontScrollButton] = useState(
		isTextLongerThanField(),
	)

	const scrolling = useRef<number | null>(null)

	const onMouseUp = useCallback(() => {
		scrolling.current && clearInterval(scrolling.current)
		scrolling.current = null
	}, [])

	const handleScroll = useCallback(
		(toRight: boolean, pointsToScroll: number = 50) => {
			const input = id ? document.getElementById(id) : undefined

			if (input) {
				toRight
					? (input.scrollLeft += pointsToScroll)
					: (input.scrollLeft -= pointsToScroll)
			}

			const scrollLeft = input?.scrollLeft || 0
			const offsetWidth = input?.offsetWidth || 0
			const scrollWidth = input?.scrollWidth || 0

			// first scroll to right
			if (!showBackScrollButton && scrollLeft > 0) {
				setShowBackScrollButton(true)
				!showFrontScrollButton && setShowFrontScrollButton(true)
			}
			// last scroll to left
			else if (showBackScrollButton && scrollLeft < 1) {
				setShowBackScrollButton(false)
				!showFrontScrollButton && setShowFrontScrollButton(true)
				onMouseUp()
			}
			// last scroll to right
			if (scrollWidth === offsetWidth + scrollLeft) {
				setShowFrontScrollButton(false)
				!showBackScrollButton && setShowBackScrollButton(true)
				onMouseUp()
			}
			// first scroll to left
			else if (!showFrontScrollButton) {
				setShowFrontScrollButton(true)
			}
		},
		[id, onMouseUp, showBackScrollButton, showFrontScrollButton],
	)

	const onMouseDown = useCallback(
		(toRight: boolean) => {
			if (!scrolling.current) {
				scrolling.current = window.setInterval(
					() => handleScroll(toRight, 10),
					100,
				)
			}
		},
		[handleScroll],
	)

	const handleBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			if (!e.target.value && !validateBlank) return
			if (semicontrolled || paddingZeros) {
				setInternalValue(
					paddingModifier(e.target.value) === '000'
						? ''
						: paddingModifier(e.target.value),
				)
			}
			const newValue = formatValue(e.target.value)
			setTimeout(() => {
				setFieldValue(field.name, newValue)
			})
			field.onBlur(e)

			setShowFrontScrollButton(false)
			onInputBlur?.(newValue)
		},
		[
			field,
			formatValue,
			onInputBlur,
			paddingModifier,
			paddingZeros,
			semicontrolled,
			setFieldValue,
			validateBlank,
		],
	)

	const [timeout, setT] = useState<null | ReturnType<typeof setTimeout>>(null)

	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setInternalValue(e.target.value)

			if (timeout) clearTimeout(timeout)
			if (e.target.type === 'textarea') {
				// in order to fix the caret jump on textareas
				const caretStart = e.target.selectionStart
				const caretEnd = e.target.selectionEnd

				field.onChange(e)
				onChangeValue && onChangeValue(e)

				requestAnimationFrame(() => {
					e.target.setSelectionRange(caretStart, caretEnd)
				})
			} else {
				// No blocking input while updating external state
				setT(
					setTimeout(() => {
						field.onChange(e)
						onChangeValue && onChangeValue(e)
					}, 100),
				)
			}

			setShowFrontScrollButton(isTextLongerThanField())
		},
		[field, onChangeValue, isTextLongerThanField, timeout],
	)

	const $inputProps = {
		...(disableMaxLength === false ? { maxLength: maxLength } : {}),
		...inputProps,
	}

	useEffect(() => {
		const value = (field.value as string) || ''
		if (paddingZeros) {
			const formattedValue = paddingModifier(value)
			setInternalValue(formattedValue === '000' ? '' : formattedValue)
		} else {
			setInternalValue(value)
		}
	}, [field.name, field.value, paddingZeros, paddingModifier])

	return (
		<UncontrolledInput
			{...props}
			onChange={handleChange}
			onClick={() => {
				const value = field.value ? '' + field.value : ''
				setInternalValue(value)
			}}
			onBlur={handleBlur}
			onKeyPress={e => {
				e.key === 'Enter' && setShowFrontScrollButton(false)
			}}
			onKeyDown={e => {
				//As e followed by a number is considered a number (ex: 1e1 is considered a number), number inputs
				// accept 'e' value. To prevent this, we use the code below.
				//Also avoiding "-" in number inputs, if onlyPositiveNumbers is true.
				if (
					props.type === 'number' &&
					(e.key === 'e' ||
						((onlyPositiveNumbers || strictlyPositive) && e.key === '-'))
				) {
					e.preventDefault()
				}
				if (strictlyPositive && e.key === '0' && !field.value) {
					e.preventDefault()
				}
				if (
					strictlyDigit &&
					(e.key === '.' || e.key === ',' || e.key === '+')
				) {
					e.preventDefault()
				}
			}}
			name={field.name}
			// Fix: TEL-583
			// if user is editing a note the typed text must not be overwritten by subsequent data that is coming from the API
			value={internalValue}
			inputProps={$inputProps}
			error={showError}
			helperText={
				props.helperText && !fieldError ? (
					props.helperText
				) : showError ? (
					<InputHelperTextWithIcon>
						{capitalizeFirstLetter(
							t(
								typeof fieldError === 'string'
									? fieldError
									: JSON.stringify(fieldError),
							),
						)}
					</InputHelperTextWithIcon>
				) : undefined
			}
			InputProps={
				showScrollButtons
					? {
							id,
							startAdornment: showBackScrollButton ? (
								<StyledIconButton
									type={'button'}
									onClick={() => handleScroll(false)}
									onMouseDown={() => onMouseDown(false)}
									onMouseUp={onMouseUp}
								>
									<ChevronLeftIcon color={'secondary'} />
								</StyledIconButton>
							) : null,
							endAdornment: showFrontScrollButton ? (
								<StyledIconButton
									type={'button'}
									onClick={() => handleScroll(true)}
									onMouseDown={() => onMouseDown(true)}
									onMouseUp={onMouseUp}
								>
									<ChevronRightIcon color={'secondary'} />
								</StyledIconButton>
							) : null,
					  }
					: {
							id,
							endAdornment:
								clearable && internalValue ? (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												setInternalValue('')
												setTimeout(() => setFieldValue(field.name, ''), 50)
											}}
											edge="end"
										>
											<CloseIcon />
										</IconButton>
									</InputAdornment>
								) : undefined,
					  }
			}
			InputLabelProps={{
				htmlFor: id,
				shrink: !!internalValue || !!field.value || showInstantPlaceholder,
			}}
		>
			{children}
		</UncontrolledInput>
	)
}

export const ScrollButtonsInput = (props: Props) => (
	<Input showScrollButtons {...props} />
)

export default Input
